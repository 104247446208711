import tippy from 'tippy.js';

const inputTooltip = () => {
  const items = document.querySelectorAll('.js-tooltip-item');
  if (items.length === 0) return;

  items.forEach(item => {
    const getText = () => {
      return item.getAttribute('data-tooltip-text');
    };

    const tooltipDirection = item.getAttribute('data-tooltip-direction');

    let offset;

    if (tooltipDirection === 'bottom' || tooltipDirection === 'top') {
      offset = [0, 10];
    } else {
      offset = [0, 10];
    }

    tippy(item, {
      placement: tooltipDirection,
      content: `${getText()}`,
      arrow: false,
      delay: [0, 200],
      touch: false,
      offset: offset,
      onTrigger(instance) {
        instance.setContent(getText());
        if (instance.reference.getAttribute('data-tooltip-flag') === 'true') {
          instance.popper.classList.add('is-hidden');
        } else {
          instance.popper.classList.remove('is-hidden');
        }
      },
    });

  });
};

export default inputTooltip;
