// いったんeslintを除外
/* eslint-disable */
const VALIDATED_CLASS = "is-validated";

const validatePasswordAreaList = document.querySelectorAll(
  ".js-validate-password-area",
);

const checkPassword = (event) => {
  const password = event.currentTarget.querySelector("input");
  const passwordValue = password.value;
  const validationItems = event.currentTarget.querySelectorAll(
    ".js-validate-password-area-item",
  );

  validationItems.forEach((item) => {
    const validateType = item.getAttribute("data-validate-type");
    const regex = getRegex(validateType);

    if (regex.test(passwordValue)) {
      item.classList.add(VALIDATED_CLASS);
    } else {
      item.classList.remove(VALIDATED_CLASS);
    }
  });
};

const getRegex = (validateType) => {
  switch (validateType) {
    case "length":
      return /^.{10,255}$/;
    case "lowercase":
      return /[a-z]/;
    case "uppercase":
      return /[A-Z]/;
    case "digits":
      return /\d/;
    case "symbols":
      return /[`~_+\-=!@#$%^&*(),.?":;{}¥'|<>\\/\[\]]/;
    default:
      return /.*/;
  }
};

const init = () => {
  if (validatePasswordAreaList.length === 0) return;

  validatePasswordAreaList.forEach((validatePasswordArea) => {
    validatePasswordArea.addEventListener("input", checkPassword);
  });
};

export default init;
