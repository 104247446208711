import MicroModal from "micromodal";

const trigger = document.querySelectorAll(".js-modal-trigger");
const closeButton = document.querySelectorAll(".js-modal-close");

const init = () => {
  if (trigger.length === 0) return;

  const scrollTop = (modal) => {
    const scrollableElement = modal.querySelector(".c-modal-content__inner");
    scrollableElement.scrollTop = 0; // スクロール位置をトップに戻す
  };

  MicroModal.init({
    openTrigger: "data-micromodal-trigger",
    closeTrigger: "data-micromodal-close",
    openClass: "is-open",
    disableScroll: true,
    disableFocus: true,
    awaitOpenAnimation: false,
    awaitCloseAnimation: false,
    onShow: scrollTop,
  });

  if (closeButton.length === 0) return;
  const agreementTarget = document.querySelector(".js-check-modal");
  const formSubmitButton = document.querySelector(".js-modal-submit");
  if (!agreementTarget && !formSubmitButton) return;

  const handleCheck = (e) => {
    const checkValue = e.target.getAttribute("data-check");
    const agreementCheckbox = agreementTarget.querySelector(
      "input[type='checkbox']",
    );
    if (checkValue === "true") {
      agreementTarget.classList.add("is-active");
      agreementCheckbox.checked = true;
      formSubmitButton.removeAttribute("disabled");
    } else if (checkValue === "false") {
      agreementTarget.classList.add("is-active");
      agreementCheckbox.checked = false;
      formSubmitButton.setAttribute("disabled", "disabled");
    }
  };

  closeButton.forEach((trigger) => {
    trigger.addEventListener("click", handleCheck);
    trigger.addEventListener("touchend", handleCheck);
  });
};

export default init;
