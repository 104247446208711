const triggers = document.querySelectorAll(".js-select-toggle-input");

const init = () => {
  if (triggers.length === 0) return;

  triggers.forEach((trigger) => {
    const hiddenArea = document.querySelector(
      `#${trigger.getAttribute("data-target")}`,
    );

    const hiddenInputs = hiddenArea.querySelectorAll("input");

    const resetInputValue = () => {
      hiddenInputs.forEach((input) => {
        input.value = "";
      });
    };

    const toggleInput = () => {
      const selectValue = trigger.getAttribute("data-open-value");

      if (trigger.value === selectValue) {
        hiddenArea.style.display = "block";
      } else {
        hiddenArea.style.display = "none";
        resetInputValue();
      }
    };

    toggleInput();

    trigger.addEventListener("change", toggleInput);
  });
};

export default init;
