const init = () => {
  const elementFileButtons = document.querySelectorAll(".js-preview-button");
  const formDropPreview = document.querySelector(".l-formDrop__preview");

  if (elementFileButtons.length === 0) return;
  let fileArray = [];

  // fileのinputを作成する関数
  const createFileInput = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".pdf, .jpg, .jpeg, .png";
    fileInput.multiple = true;
    fileInput.style.display = "none";
    fileInput.addEventListener("change", (event) => {
      const newFiles = Array.from(event.target.files);
      // console.log(newFiles);
      // fileArray.push(...files);
      fileArray.push(...newFiles);
      newFiles.forEach((file) => {
        const previewItem = document.createElement("div");
        previewItem.classList.add("l-formDrop__preview__item");

        const imgElement = document.createElement("img");
        const deleteButton = document.createElement("button");

        imgElement.src = URL.createObjectURL(file);
        imgElement.width = 560;
        imgElement.height = 350;
        imgElement.decoding = "async";
        imgElement.loading = "lazy";

        deleteButton.type = "button";
        deleteButton.classList.add("c-delete-button");
        deleteButton.textContent = "削除";
        deleteButton.addEventListener("click", () => {
          formDropPreview.removeChild(previewItem);
          fileArray = fileArray.filter((item) => item !== file); // ファイルを配列から削除
        });

        previewItem.appendChild(imgElement);
        previewItem.appendChild(deleteButton);

        formDropPreview.appendChild(previewItem);
      });
      // ファイル選択をクリア
      // event.target.value = "";
    });
    // console.log(fileInput);
    return fileInput;
  };

  elementFileButtons.forEach((elementFileButton) => {
    const fileInput = createFileInput();
    elementFileButton.appendChild(fileInput);
    elementFileButton.addEventListener("click", () => {
      fileInput.click();
      // console.log("クリック");
    });
  });
};

export default init;
