const inputModule = () => {
  const VISIBLE_CLASS = 'is-visible';
  const inputElements = document.querySelectorAll(".js-input-area");

  if (inputElements.length === 0) return;

  inputElements.forEach((element) => {

    const input = element.querySelector("input");
    const resetButton = element.querySelector(".js-input-reset");
    const passwordButton = element.querySelector(".js-toggle-password");

    const resetValue = () => {
      input.value = "";
    };

    if (resetButton) {
      resetButton.addEventListener("click", resetValue);
    }

    const checkValue = () => {
      if (input.value.length) {
        input.classList.remove("is-error");
        resetButton.style.visibility = "visible";
      } else {
        resetButton.style.visibility = "hidden";
      }
    };

    if (resetButton) {
      checkValue();
      input.addEventListener("input", checkValue);
    }


    const toggleHidden = () => {
      if (passwordButton.getAttribute("aria-label") === "パスワード表示する") {
        input.type = "text";
        passwordButton.ariaLabel = "パスワード隠す";
        passwordButton.classList.add(VISIBLE_CLASS);
      } else {
        input.type = "password";
        passwordButton.ariaLabel = "パスワード表示する";
        passwordButton.classList.remove(VISIBLE_CLASS);
      }
    };

    if (passwordButton) {
      passwordButton.addEventListener("click", toggleHidden);
    }
  });
};

const init = () => {
  inputModule();
};

export default init;
