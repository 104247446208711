
const init = (itemSelector = ".js-spread-link-item", linkSelector = "a") => {
  const items = document.querySelectorAll(itemSelector);

  if (items.length === 0) return;

  items.forEach((item) => {
    const link = item.querySelector(linkSelector);

    if (!link) return;
    item.style.cursor = "pointer";
    item.classList.add("has-link");

    const clickHandler = (event) => {
      event.stopPropagation();
      const selection = document.getSelection();
      if (selection.isCollapsed) {
        link.click();
      }
    };
    item.addEventListener("click", clickHandler);
  });
};

export default init;
