const menuButton = document.getElementById('js-menu-trigger');
const menuButtonTextElement = document.getElementById('js-menu-trigger-text');
const globalMenu = document.getElementById('sp-nav');
const ACTIVE_CLASS = 'is-active';

let currentPos;

// MENU関連
const toggleBody = (isOpen) => {
  const body = document.body;

  if (isOpen) {
    currentPos = window.scrollY;
    body.style.position = 'fixed';
    body.style.top = ` ${currentPos}px`;
    body.style.left = '0';
    body.style.width = '100%';
    body.style.height = '100%';
  } else {
    body.style.position = 'static';
    body.style.top = `auto`;
    body.style.left = 'auto';
    body.style.width = 'auto';
    body.style.height = 'auto';
    window.scrollTo(0, currentPos);
  }
};

const toggleMenu = (isOpen) => {
  const ariaExpandedValue = isOpen ? 'true' : 'false';
  const ariaLabelValue = isOpen ? 'メニューを閉じる' : 'メニューを開く';
  const ariaHiddenValue = isOpen ? 'false' : 'true';

  menuButton.setAttribute('aria-expanded', ariaExpandedValue);
  menuButton.setAttribute('aria-label', ariaLabelValue);
  globalMenu.setAttribute('aria-hidden', ariaHiddenValue);

  if (menuButtonTextElement) {
    const menuText = isOpen ? 'とじる' : 'メニュー';
    menuButtonTextElement.textContent = menuText;
  }

  toggleBody(isOpen);

  if (isOpen) {
    menuButton.classList.add(ACTIVE_CLASS);
  } else {
    menuButton.classList.remove(ACTIVE_CLASS);
  }
};

const clickHandler = e => {
  if (e.currentTarget.getAttribute('aria-expanded') === 'false') {
    toggleMenu(true);
  } else {
    toggleMenu(false);
  }
};

const mediaQuery = window.matchMedia('(min-width: 768px)');

const mediaQueryHandler = (e) => {
  if (e.matches) {
    toggleMenu(false);
  }
};

export default () => {
  if (menuButton) {
    menuButton.addEventListener('click', clickHandler);
    mediaQuery.addEventListener('change', mediaQueryHandler);
    mediaQueryHandler(mediaQuery);
  }
};
