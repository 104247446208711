export default () => {
  const targets = Array.prototype.slice.call(document.querySelectorAll('a[href^="#"]'), 0);

  if (targets.length === 0) {
    return false;
  }

  function easing(t, b, c, d) {
    return -c * (t /= d) * (t - 2) + b;
  }

  const animeSpeed = 400;

  targets.forEach(target => {

    target.addEventListener('click', event => {
      event.preventDefault();

      if (document.body.classList.contains('is-scroll-busy')) {
        return false;
      }

      document.body.classList.add('is-scroll-busy');

      const href = target.getAttribute('href');
      const scrollStopTarget = document.querySelector(href == '#' || href == '' ? 'html' : href);

      const header = document.getElementById('header');

      const adjust = 0;


      //移動先取得
      let scrollStopTop;

      scrollStopTop = scrollStopTarget.getBoundingClientRect().top - header.clientHeight - adjust;

      //現在のスクロール量
      const scrollTop = (window.scrollY || document.documentElement.scrollTop);

      //アニメーション開始時間
      const start = new Date();
      //スクロールアニメーション関数
      let animestop = false;

      function mainAnime() {
        //イベント発生後の経過時間
        let elapsedTime = new Date() - start;

        //アニメーション終了処理
        if (animestop) {
          //実行中class削除
          document.body.classList.remove('is-scroll-busy');

          return false;
        }

        if (elapsedTime >= animeSpeed) {
          elapsedTime = animeSpeed;
          animestop = true;
        }

        //スクロール処理
        window.scrollTo(
          0,
          //「アニメーションの経過時間」,「始点」,「変化量」,「変化にかける時間」
          easing(elapsedTime, scrollTop, scrollStopTop, animeSpeed)
          // scrollStopTop - scrollTop
        );

        requestAnimationFrame(mainAnime);

      }

      //アニメーション初回呼び出し
      requestAnimationFrame(mainAnime);
    });
  });
};
