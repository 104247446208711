// const numberCount = document.querySelector(".js-number-count");
// let time = 180;

// const countdownTimer = () => {
//   if (time > 0) {
//     setTimeout(countdownTimer, 1000);
//     numberCount.innerText = time + "s";
//     time--;
//   } else {
//     numberCount.innerHTML =
//       "<div class='c-number-count is-done'>コードを取得</div>";
//   }
// };

// codeLinkのボタンクリック後
const codeLink = document.querySelector(".js-code-link");
const formMessage = document.querySelector(".js-form-message");

const getCode = () => {
  codeLink.addEventListener("click", () => {
    formMessage.classList.add("is-active");
    setTimeout(() => {
      formMessage.classList.remove("is-active");
    }, 2000);
  });
};

const init = () => {
  // if (!numberCount) return;
  // countdownTimer();
  // numberCount.addEventListener("click", () => {
  //   time = 180;
  //   countdownTimer();
  // });
  if (!codeLink) return;
  if (!formMessage) return;
  getCode();
};

export default init;
