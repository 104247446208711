import initHeaderModules from "./modules/headerModules";
import initSmoothscroll from "./modules/smoothscroll";
import initFormModules from "./modules/formModules";
import initTooltip from "./modules/tooltip";
import initAccordion from "./modules/accordion";
import initSpreadLink from "./modules/spreadLink";
import init2faCount from "./modules/2faCount";
import initSelectToggleInput from "./modules/selectToggleInput";
// import initToggleCheckbox from "./modules/toggleCheckbox";
import initValidatePassword from "./modules/validatePassword";
import rubyButton from "./modules/rubyButton";
// import selectRelation from "./modules/selectRelation";
import initOpenFileButton from "./modules/openFileButton";
import initAnimation, { refreshScrollTrigger } from "./modules/animation";
import initModal from "./modules/modal";

document.addEventListener("DOMContentLoaded", () => {
  initHeaderModules();
  initSmoothscroll();
  initFormModules();
  initTooltip();
  initAccordion();
  initAnimation();
  initSpreadLink();
  init2faCount();
  initSelectToggleInput();
  initValidatePassword();
  // initToggleCheckbox();
  rubyButton();
  // selectRelation();
  initOpenFileButton();
  initModal();
});

window.addEventListener("load", () => {
  refreshScrollTrigger();
});
