import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Observer } from 'gsap/Observer';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Observer);

const refreshScrollTrigger = () => {
  ScrollTrigger.refresh();
};

const togglePageTopButton = () => {
  const pageTopButton = document.querySelector('#js-page-top-button');
  if (!pageTopButton) return;

  let mm = gsap.matchMedia();

  const getWindowHeight = () => {
    return window.innerHeight;
  };

  mm.add("(min-width: 768px)", () => {
    gsap.set(pageTopButton, {
      autoAlpha: 0
    });

    gsap.to(pageTopButton, {
      autoAlpha: 1,
      scrollTrigger: {
        trigger: 'body',
        start: () => `top+=${getWindowHeight()} top`,
        toggleActions: 'play none none reverse',
      }
    });
  });
};

export default () => {
  togglePageTopButton();
};

export { refreshScrollTrigger };
