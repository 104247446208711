import { gsap } from 'gsap';

const animationTiming = {
  duration: 0.4,
  ease: 'power3.out'
};

const closeAnimation = (content, element) => gsap.to(content, {
  height: 0,
  ...animationTiming,
  overwrite: true,
  onComplete: () => {
    element.removeAttribute('open');
  },
});


const openAnimation = content => gsap.fromTo(
  content,
  {
    height: 0,
  },
  {
    height: 'auto',
    ...animationTiming,
    overwrite: true,
  }
);

const init = () => {
  const accordionElements = document.querySelectorAll('.js-accordion');
  const OPEN_CLASS = 'is-open';

  accordionElements.forEach(element => {
    const trigger = element.querySelector('.js-accordion-trigger');
    const triggerText = element.querySelector('.js-accordion-trigger-text');
    const tooltip = element.querySelector('.js-tooltip-item');
    const target = element.querySelector('.js-accordion-content');

    const isOpen = element.classList.contains(OPEN_CLASS);

    target.style.overflow = 'hidden';

    const toggleText = (isOpen) => {
      if (isOpen) {
        if (triggerText) {
          triggerText.textContent = 'とじる';
        }
        if (tooltip) {
          const text = tooltip.getAttribute('data-tooltip-text');
          const replaceText = text.replace('ひらく', 'とじる');
          tooltip.setAttribute('data-tooltip-text', replaceText);
        }
      } else {
        if (triggerText) {
          triggerText.textContent = 'ひらく';
        }
        if (tooltip) {
          const text = tooltip.getAttribute('data-tooltip-text');
          const replaceText = text.replace('とじる', 'ひらく');
          tooltip.setAttribute('data-tooltip-text', replaceText);
        }
      }
    };

    if (isOpen) {
      openAnimation(target).restart().seek(0.4);
    } else {
      closeAnimation(target, element).restart().seek(0.4);
    }

    toggleText(isOpen);


    const handleClick = (event) => {
      event.preventDefault();
      element.classList.toggle(OPEN_CLASS);
      const isOpen = element.classList.contains(OPEN_CLASS);

      if (isOpen) {
        openAnimation(target, element).restart();
      } else {
        closeAnimation(target, element).restart();
      }

      toggleText(isOpen);
    };

    trigger.addEventListener('click', handleClick);

  });
};

export default init;
