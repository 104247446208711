const init = () => {
  const rubyButton = document.querySelector(".js-ruby-button");
  const rubyTextBox = document.querySelector(".js-ruby-text");

  const rubyButtonToggle = () => {
    if (!rubyButton || !rubyTextBox) return;
    rubyButton.addEventListener("click", () => {
      if (rubyTextBox.textContent === "ふりがなをつける") {
        rubyTextBox.textContent = "ふりがなをはずす";
      } else {
        rubyTextBox.textContent = "ふりがなをつける";
      }
    });
  };

  rubyButtonToggle();
};

export default init;
